<template>
  <div class="home" :style="cStyle">
    <div id="map1Item" :style="cStyles"></div>
<!--    <el-amap id="map1Item" :mapStyle="mapStyle" :center="center" :events="events"></el-amap>-->
  </div>
</template>

<script>
import Vue from "vue";
import AMapLoader from '@amap/amap-jsapi-loader';

import { mapGetters } from "vuex";


export default {
  name: 'Home',
  data() {
    return {
      mymap: null,
      markers: [],
      loading: false,
    }
  },
  props: {
    item: {
      type: Object,
    },
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.mapInit();
      // if (this.item.optionsData.txtStyle.arrLag.length > 0) {
      //   this.markers = this.item.optionsData.txtStyle.arrLag
      //   // this.getMarker(this.item.optionsData.txtStyle.arrLag)
      // }
    })
  },
  computed: {
    // ...mapGetters(["currentTarget"]),
    cStyle() {
      return {
        width: this.item.width + 'px',
        height: this.item.height + 'px',
      }
    },
    cStyles() {
      return {
        width: this.item.width - 10 + 'px',
        height: this.item.height - 10 + 'px',
      }
    },
  },
  watch: {
    item: {
      handler(nVal,oVal) {
        if (!nVal) {
          return false
        }
        let data = nVal.optionsData ? nVal : {}
        // this.mymap.clearMap()
        // this.mymap.setZoomAndCenter(data.optionsData.txtStyle.zooms, data.optionsData.txtStyle.centerMap);
        // this.getMarker(data.optionsData.txtStyle.arrLag)

      },
      // deep: true
    }
  },
  methods: {
    // 初始加载底图
    mapInit() {
      AMapLoader.load({
        "key": "8ca34fc476248913068d55c7e5320abf",              // 申请好的Web端开发者Key，首次调用 load 时必填
        //"version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": ['AMap.ToolBar', 'AMap.DistrictSearch'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等

      }).then(AMap => {
        const map = new AMap.Map('map1Item', {
          expandZoomRange: true,
          resizeEnable: true,
          dragEnable: true,
          center: this.item.optionsData.txtStyle.centerMap,
          zoom: this.item.optionsData.txtStyle.zooms, //8.35,
          viewMode: '2D', // 地图模式
          mapStyle: "amap://styles/darkblue",
        })
        this.mymap = map

        map.addControl(new AMap.ToolBar({
          // 简易缩放模式，默认为 false
          liteStyle: true
        }));

        // 行政区查询服务
        var district = new AMap.DistrictSearch({
          subdistrict: 0,
          extensions: "all",
          level: "city",
        });

        //绘制地图，只显示当前行政区域，自定义边境颜色
        district.search("重庆", (status, result) => {
          // 外多边形坐标数组和内多边形坐标数组
          var outer = [
            new AMap.LngLat(-360, 90, true),
            new AMap.LngLat(-360, -90, true),
            new AMap.LngLat(360, -90, true),
            new AMap.LngLat(360, 90, true),
          ];
          var holes = result.districtList[0].boundaries;
          var pathArray = [outer];
          pathArray.push.apply(pathArray, holes);
          var polygon = new AMap.Polygon({
            pathL: pathArray,
            strokeColor: "#0469c1",
            strokeWeight: 1.5,
            fillColor: "#132230",
            fillOpacity: 1,
          });
          polygon.setPath(pathArray);
          map.add(polygon);

        });

        // if (this.$store.state.echartArr.length > 0) {
        //   //地图点击事件
        //   map.on("click", (e) => {
        //     this.setMarker(e)
        //     this.getMarker(this.markers)
        //     this.item.optionsData.txtStyle.arrLag = this.markers
        //   });
        // } else {
        //   this.getMarker(this.item.optionsData.txtStyle.arrLag)
        // }


      }).catch(e => {
        console.log(e);
      })

    },
    setMarker(e) {
      this.markers.push({
        position: [e.lnglat.getLng(), e.lnglat.getLat()],
        icon: this.item.optionsData.txtStyle.icon,
        label: {
          content: '安全帽'
        },
        animation: 'AMAP_ANIMATION_DROP'
      })
    },
    getMarker(val) {
      let dd = JSON.parse(JSON.stringify(val))
      dd.forEach((item,index) => {
        new AMap.Marker({
          position: item.position,
          icon: `${this.$BASEIMG}Upload_Files/${sessionStorage.getItem('testKeyId')}/${item.icon}`,
          label: {content: item.label.content, offset: new AMap.Pixel(0, -10), direction: 'top'},
          map: this.mymap,
          animation: index === dd.length - 1 ? 'AMAP_ANIMATION_DROP' : 'AMAP_ANIMATION_NONE'
        });
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.home {
  padding: 5px;
  #map {
    /deep/ .amap-icon {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>
